<template>
    <div class="text-center box">
        <div class="my-4">
            <img class="img-fluid logo" :src="img">
        </div>
        <h4 class="my-2">{{ title }}</h4>
        <div class="quote font-weight-lighter py-4"><em class="small-testi"><slot></slot></em></div>
        <div>
            <img class="five-star testing" :src="require('../../assets/symbol/five_star.png')">
        </div>
    </div>
</template>

<script>
  export default {
    name: "Comment",
    props: ["img", "title"],
  }
</script>

<style scoped lang="scss">
    .testing {
        display: inline-block;
    }

    .box {
        width: 700px;
        height: 500px;
        box-shadow: 2px 10px 20px #E5E8FF;
        padding: 4rem 6rem;
    }

    @media only screen and (max-width: 576px) {
        .box {
            width: 350px;
            height: 500px;
            box-shadow: 1px 5px 10px #E5E8FF;
            padding: 2rem 3rem;
            margin: auto;
        }

        .testing {
            display: none;
        }

        .small-testi {
            font-size: 1.0rem;
        }
    }

    .quote {
        background-image: url("../../assets/symbol/quote.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .logo {
        max-height: 4rem;
    }

    .five-star {
        height: 2rem;
    }

</style>
