<template>
  <div class="p-1" v-bind:class="{ outer: isService }" @click="showDetail">
    <div
      class="py-4 px-3 h-100 justify-content-center grid"
      v-bind:class="{
        service: isService,
        'grid-selected': isSelected,
        'border-gradient': isService,
        inner: isService,
      }"
    >
      <div v-if="!isService" class="col-xl-12 col-md-6 col-7 text-left">
        <img class="text-left grid-image" :src="img" />
      </div>
      <h2
        class="col-12 font-weight-bold pt-2 text-left grid-title"
        v-bind:class="{ 'grid-selected': isSelected }"
      >
        {{ title }}
      </h2>
      <div
        class="col-12 pt-2 text-left font-weight-light"
        v-bind:class="{ 'grid-selected': isSelected }"
      >
        <slot></slot>
      </div>
      <div v-if="!hideDetail">
        <div v-if="!isService" class="col-12 mt-2">
          <div class="text-left">
            <span>Detail</span>
            <img
              class="float-right arrow-right my-2"
              v-bind:class="{ 'arrow-big': isInSubpage }"
              :src="require('../../assets/arrow_right.png')"
            />
          </div>
        </div>
      </div>
      <div class="my-2" v-else></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: [
    'img',
    'title',
    'isService',
    'isInSubpage',
    'option',
    'isSelected',
    'hideDetail',
    'link',
  ],
  methods: {
    showDetail() {
      if (this.link) {
        location.href = `#${this.link}`
      }

      if (this.isInSubpage) {
        this.$emit('showDetail', this.option)
      }
    },
  },
}
</script>

<style scoped lang="scss">
img {
  height: 50px;
}

@include media-breakpoint-up(lg) {
  img {
    height: 80px;
  }
}

.arrow-right {
  height: 5%;
  width: 5%;
}

.arrow-right:hover {
  cursor: pointer;
}

.arrow-big {
  height: 10%;
  width: 10%;
}

.arrow-big:hover {
  cursor: pointer;
}

.grid {
  background-color: white;
  transition: 0.3s;
}

.grid:hover {
  background-color: $theme-grid-backgroud-color;
  color: white;
  cursor: pointer;
}

.service {
  color: white;
}

.service:hover {
  background-color: $theme-grid-backgroud-color-service;
  color: white;
  cursor: default;
}

.wrapper {
  transition: 0.3s;
}

.wrapper:hover {
  border-top-style: solid;
  border-top-color: $theme-background-color;
  border-bottom-style: solid;
  border-bottom-color: $theme-background-color;
  background: linear-gradient(to right, #fad961, #f76b1c);
  color: white;
}

.wrapperInner {
  transition: 0.3s;
}

.wrapperInner:hover {
  color: white;
}

.grid-image {
  height: 50px;
  width: 53px;
}

.grid-title {
  font-family: 'Arial';
  font-size: 20px;
}

@media screen and (max-width: 1023px) {
  .grid-title {
    font-size: 1.125rem;
  }
}

.grid-selected {
  background-color: $theme-grid-backgroud-color;
  color: white;
}

.border-gradient {
  transition: 0.3s;
  border: 5px solid;
  /*border-color: white;*/
  border-color: rgba(255, 255, 255, 0);
}

.border-gradient:hover {
  border: 5px solid;
  border-color: #fad860;
}

.outer {
  background: rgba(255, 255, 255, 0);
}

.inner {
  background: rgba(255, 255, 255, 0.1);
}
</style>
